import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';
import { Form, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom";
import imagemConvencao from '../../assets/img/convencao_23.png';
import imagemConvencao2024 from '../../assets/img/convencao_logo_25.svg';
import imagemFundo from '../../assets/img/fundo_convencao_25.png';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const required = value => (value ? undefined : 'Campo Obrigatório')

function Spinner() {
  return (
      <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
      <div></div><div></div><div></div><div></div><div></div>
      </div></div>
  )
}

class InscricaoConvencao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formSubmitted: false,
      error: null,
    }
  }


  onSubmit = async values => {
    await sleep(300)
    // const dev_token = '2276900aa8e0cfc2bde714738e61312b8ebe0a3b';
    const dev_token = '2276900aa8e0cfc2bde714738e61312b8ebe0a3b';
    this.setState({loading: true});
    const comp = values.comparencia === "Sim" ? true : false;
    const restri = values.restricoes === "Sim" ? true : false;
    const hotel_choice = values.hotel === "Sim" ? true : false;
    const tipo_estrutura = values.tipo_estrutura === "agente" ? true : false;
    const estadia_noite_anterior = values.estadia_noite === "Sim" ? true : false;
    const fica_para_jantar = values.jantar === "Sim" ? true : false;
    await axios.post(`https://interno.nacionalgest.pt/api/eventos/convencao/inscricao/`, {
    // await axios.post(`http://127.0.0.1:8000/api/eventos/convencao/inscricao/`, {
      nome: values.nome,
      telemovel: values.telemovel,
      email: values.email,
      is_agente: tipo_estrutura,
      comparencia: comp,
      restricoes: restri,
      hotel: hotel_choice,
      hotel_noite_anterior: estadia_noite_anterior,
      jantar: fica_para_jantar,
      tipo_restricoes: values.tipo_restricoes,
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
  }}).then((response) => {
    console.log(response.data);
    if (response.status === 200) {
      if (response.data.error) {
        this.setState({
          error:response.data.error,
          loading: false
        })
      } else {
      this.setState({
        loading:false,
        formSubmitted: true
      })
    }
  }
  }).catch((error) => {
    this.setState({
      loading: false,
    });
    alert(error);
  });
  }

  handleSubmit = values  => {
      return this.onSubmit(values)
  }

  render() {

    const allowedEmails = [
      "ana.bento@nacionalgest.pt", "ana.kohls@nacionalgest.pt", "ana.vieira@nacionalgest.pt", "ataide.cavaco@nacionalgest.pt", "carlos.jesus@nacionalgest.pt",
      "carlos.varges@nacionalgest.pt", "carolina.gual@nacionalgest.pt", "catarina.santos@nacionalgest.pt", "catia.bento@nacionalgest.pt", "catia.guerreiro@nacionalgest.pt", "catia.silva@nacionalgest.pt", "claudio.goncalves@nacionalgest.pt", "daniela.bras@nacionalgest.pt", "dario.carvalho@nacionalgest.pt", "diogo.machado@nacionalgest.pt", "dora.rocha@nacionalgest.pt", "fernando.gomes@nacionalgest.pt", "filipa.silva@nacionalgest.pt", "francisco.galinha@nacionalgest.pt", "goncalo.cigarro@nacionalgest.pt", "goncalo.fernandes@nacionalgest.pt", "helena.fernandes@nacionalgest.pt", "helena.nunes@nacionalgest.pt", "helena.oliveira@nacionalgest.pt", "joana.silva@nacionalgest.pt",
      "joana.viana@nacionalgest.pt", "joao.almeida@nacionalgest.pt", "joao.araujo@nacionalgest.pt", "joaquim.viegas@nacionalgest.pt", "jorge.silva@nacionalgest.pt", "leonor.fernandes@nacionalgest.pt", "ligia.domingos@nacionalgest.pt", "lucia.coelho@nacionalgest.pt",
      "luis.eloi@nacionalgest.pt", "luis.fernandes@nacionalgest.pt", "luis.martins@nacionalgest.pt", "luis.silva@nacionalgest.pt", "marcia.hipolito@nacionalgest.pt", "maria.goreti@nacionalgest.pt", "mario.fragoso@nacionalgest.pt", "mjose.antonio@nacionalgest.pt", "orlanda.fidalgo@nacionalgest.pt", "patricia.ferreira@nacionalgest.pt", "patricia.soares@nacionalgest.pt", "paulo.fernandes@nacionalgest.pt", "paulo.rocha@nacionalgest.pt",
      "paulo.silva@nacionalgest.pt", "pedro.dias@nacionalgest.pt", "pedro.guerreiro@nacionalgest.pt", "pedro.luzia@nacionalgest.pt", "pedro.nascimento@appacademia.pt", "pedro.pereira@nacionalgest.pt", "reinaldo.monteiro@nacionalgest.pt", "rui.goncalves@nacionalgest.pt", "rui.pinto@nacionalgest.pt", "rute.risio@nacionalgest.pt", "sandra.montes@nacionalgest.pt", "sandra.soares@nacionalgest.pt", "sara.gomes@nacionalgest.pt", "sergio.jesus@nacionalgest.pt", "silvia.costa@nacionalgest.pt", "sofia.guerreiro@nacionalgest.pt", "susana.silva@nacionalgest.pt", "susana.tome@nacionalgest.pt", "tiago.nunes@nacionalgest.pt", "vania.barbara@nacionalgest.pt",
      "vania.nobre@nacionalgest.pt", "vera.custodio@nacionalgest.pt", "madalena.costa@nacionalgest.pt", "luis._ferreira@hotmail.com", "luis.arjuna@agente-nacionalgest.pt", "anibal.viegas@agente-nacionalgest.pt", "valeria.ramalho@nacionalgest.pt", "dartacosta@gmail.com", "micaelapereira80@gmail.com"
  ]

  const notAllowToStay = [
    "fernando.coelho@agente-nacionalgest.pt"
  ]



    return (
      <div>
      <div className="content-wrapper">
            <Header />
            {/* /header */}
            <section className="wrapper bg-light">
        			<div className="container pt-10 pb-8 pt-md-14 pb-md-12 text-center">
        				<div className="row">
        					<div className="col-lg-8 mx-auto">
        						<h1 className="display-1 mb-3">Inscrição Convenção Anual <br />NacionalGest 2025</h1>
        					</div>
        				</div>
        			</div>
        		</section>
        		<section className="wrapper bg-light">
            <div className="container">
        			<div className="card shadow-lg mb-15 mb-md-14">
        				<div className="row gx-0">
        					<div className="col-lg-6">
        						<div className="p-10 p-md-11 p-lg-11">
                    {this.state.error === 1 && (
                      <>
                      <h1>Já respondeu a este formulário!</h1>
                      <p>Uma resposta com este e-mail já foi submetida neste formulário. </p>
                      <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                      </>
                    )}
                    {this.state.formSubmitted && (
                      <>
                      <h1>Obrigado por responder!</h1>
                      <p>Mais perto da data voltaremos a entrar em contacto com mais pormenores. </p>
                      </>

                    )}
                    {!this.state.formSubmitted && this.state.error === null && (
                    <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{
                     }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>É com grande entusiasmo que o/a convidamos para a <b>Convenção Anual da NacionalGest</b>, que se realizará no dia <b>5 de abril de 2025</b>, no <b>Alambique Hotel Resort & Spa, no Fundão.</b></p>
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>Sob o tema <b>"Informar para Proteger"</b>, este encontro será um momento marcante de partilha, reflexão e crescimento. Teremos um dia repleto de <b>palestras inspiradoras, debates estratégicos e insights valiosos,</b> fundamentais para o nosso caminho conjunto.</p>
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>À noite, prepare-se para um ambiente descontraído e envolvente, repleto de <b>momentos de convívio, surpresas e uma viagem sensorial pelos sabores e tradições culturais.</b></p>
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>No dia <b>6 de abril</b>, até à hora de check-out, poderá usufruir dos serviços comuns do <b>Golden Rock Water Spa</b> (piscinas interiores aquecidas, jacuzzis, saunas, banho turco, hammam e ginásio). Para ter acesso, é obrigatória a utilização de touca e chinelos.</p>
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>A sua presença faz a diferença! Junte-se a nós nesta celebração do nosso propósito e do caminho que construímos juntos.</p>
                            <h6 className="mb-3">Estrutura</h6>
                              <div className="form-label-group">
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="tipo_estrutura" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="tipo_estrutura"
                                    component="input"
                                    type="radio"
                                    value="interno"
                                    required
                                  />{' '}
                                  Equipa Interna
                                </label>
                                </div>
                                <div className="form-check mb-4">
                                  <label className="form-check-label" htmlFor="tipo_estrutura" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="tipo_estrutura"
                                    component="input"
                                    type="radio"
                                    value="agente"
                                    required
                                  />{' '}
                                  Rede de Agentes
                                </label>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="nome" name="nome" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="nome" />
                                  <label htmlFor="nome">Nome</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {values.tipo_estrutura === "agente" && (
                              <Field id="email" name="email" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select" required>
                                        <option disabled selected value="">Seleccione o seu e-mail</option>
                                        <option value="anibal.viegas@agente-nacionalgest.pt">anibal.viegas@agente-nacionalgest.pt</option>
                                        <option value="antoniapereira@triunfalseguros.pt">antoniapereira@triunfalseguros.pt</option>
                                        <option value="antonino.oliveira@agente-nacionalgest.pt">antonino.oliveira@agente-nacionalgest.pt</option>
                                        <option value="carlos.miranda@nacionalgest.pt">carlos.miranda@nacionalgest.pt</option>
                                        <option value="catarina.galvao@nacionalgest.pt">catarina.galvao@nacionalgest.pt</option>
                                        <option value="dartacosta@gmail.com">dartacosta@gmail.com</option>
                                        <option value="fernando.coelho@agente-nacionalgest.pt">fernando.coelho@agente-nacionalgest.pt</option>
                                        <option value="francisco.frade@nacionalgest.pt">francisco.frade@nacionalgest.pt</option>
                                        <option value="joao.trindade@nacionalgest.pt">joao.trindade@nacionalgest.pt</option>
                                        <option value="luis._ferreira@hotmail.com">luis._ferreira@hotmail.com</option>
                                        <option value="luis.arjuna@agente-nacionalgest.pt">luis.arjuna@agente-nacionalgest.pt</option>
                                          <option value="madalena.costa@nacionalgest.pt">madalena.costa@nacionalgest.pt</option>
                                            <option value="rui.mendes@nacionalgest.pt">rui.mendes@nacionalgest.pt</option>

                                          <option value="sandra.rodrigues@nacionalgest.pt">sandra.rodrigues@nacionalgest.pt</option>
                                    </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                          )}
                          {values.tipo_estrutura === "interno" && (
                            <Field id="email" name="email" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <select {...input} className="form-select" type="text" component="select" required>
                                      <option disabled selected value="">Seleccione o seu e-mail</option>
                                        <option value="ana.bento@nacionalgest.pt">ana.bento@nacionalgest.pt</option>
                                        <option value="ana.fernandes@nacionalgest.pt">ana.fernandes@nacionalgest.pt</option>
                                        <option value="ana.frazao@nacionalgest.pt">ana.frazao@nacionalgest.pt</option>
                                        <option value="ana.kohls@nacionalgest.pt">ana.kohls@nacionalgest.pt</option>
                                        <option value="ana.vieira@nacionalgest.pt">ana.vieira@nacionalgest.pt</option>
                                        <option value="antonio.melao@nacionalgest.pt">antonio.melao@nacionalgest.pt</option>
                                        <option value="armandino.courelas@nacionalgest.pt">armandino.courelas@nacionalgest.pt</option>
                                        <option value="ataide.cavaco@nacionalgest.pt">ataide.cavaco@nacionalgest.pt</option>
                                        <option value="carla.simoes@nacionalgest.pt">carla.simoes@nacionalgest.pt</option>
                                        <option value="carlos.jesus@nacionalgest.pt">carlos.jesus@nacionalgest.pt</option>
                                        <option value="carlos.marreiros@nacionalgest.pt">carlos.marreiros@nacionalgest.pt</option>
                                        <option value="carlos.monteiro@nacionalgest.pt">carlos.monteiro@nacionalgest.pt</option>
                                        <option value="carlos.varges@nacionalgest.pt">carlos.varges@nacionalgest.pt</option>
                                        <option value="carolina.gual@nacionalgest.pt">carolina.gual@nacionalgest.pt</option>
                                        <option value="carolina.marques@nacionalgest.pt">carolina.marques@nacionalgest.pt</option>
                                        <option value="catarina.antunes@nacionalgest.pt">catarina.antunes@nacionalgest.pt</option>
                                        <option value="catarina.santos@nacionalgest.pt">catarina.santos@nacionalgest.pt</option>
                                        <option value="catia.bento@nacionalgest.pt">catia.bento@nacionalgest.pt</option>
                                        <option value="catia.guerreiro@nacionalgest.pt">catia.guerreiro@nacionalgest.pt</option>
                                        <option value="catia.silva@nacionalgest.pt">catia.silva@nacionalgest.pt</option>
                                        <option value="claudia.afonso@nacionalgest.pt">claudia.afonso@nacionalgest.pt</option>
                                        <option value="claudio.dourado@nacionalgest.pt">claudio.dourado@nacionalgest.pt</option>
                                        <option value="claudio.goncalves@nacionalgest.pt">claudio.goncalves@nacionalgest.pt</option>
                                        <option value="cristina.lourenco@nacionalgest.pt">cristina.lourenco@nacionalgest.pt</option>
                                        <option value="daniela.bras@nacionalgest.pt">daniela.bras@nacionalgest.pt</option>
                                        <option value="dario.carvalho@nacionalgest.pt">dario.carvalho@nacionalgest.pt</option>
                                        <option value="diana.carvalho@nacionalgest.pt">diana.carvalho@nacionalgest.pt</option>
                                        <option value="diogo.machado@nacionalgest.pt">diogo.machado@nacionalgest.pt</option>
                                        <option value="diogo.rodrigues@nacionalgest.pt">diogo.rodrigues@nacionalgest.pt</option>
                                        <option value="dora.rocha@nacionalgest.pt">dora.rocha@nacionalgest.pt</option>
                                        <option value="elsa.tito@nacionalgest.pt">elsa.tito@nacionalgest.pt</option>
                                        <option value="emilia.santos@nacionalgest.pt">emilia.santos@nacionalgest.pt</option>
                                        <option value="fabio.malheiro@nacionalgest.pt">fabio.malheiro@nacionalgest.pt</option>
                                        <option value="fatima.prazeres@nacionalgest.pt">fatima.prazeres@nacionalgest.pt</option>
                                        <option value="felismina.soares@nacionalgest.pt">felismina.soares@nacionalgest.pt</option>
                                        <option value="fernando.gomes@nacionalgest.pt">fernando.gomes@nacionalgest.pt</option>
                                        <option value="filipa.silva@nacionalgest.pt">filipa.silva@nacionalgest.pt</option>
                                        <option value="filipe.teixeira@nacionalgest.pt">filipe.teixeira@nacionalgest.pt</option>
                                        <option value="flora.xavier@nacionalgest.pt">flora.xavier@nacionalgest.pt</option>
                                        <option value="francisco.galinha@nacionalgest.pt">francisco.galinha@nacionalgest.pt</option>
                                        <option value="frederico.alves@nacionalgest.pt">frederico.alves@nacionalgest.pt</option>
                                        <option value="goncalo.cigarro@nacionalgest.pt">goncalo.cigarro@nacionalgest.pt</option>
                                        <option value="goncalo.fernandes@nacionalgest.pt">goncalo.fernandes@nacionalgest.pt</option>
                                        <option value="helena.fernandes@nacionalgest.pt">helena.fernandes@nacionalgest.pt</option>
                                        <option value="helena.nunes@nacionalgest.pt">helena.nunes@nacionalgest.pt</option>
                                        <option value="helena.oliveira@nacionalgest.pt">helena.oliveira@nacionalgest.pt</option>
                                        <option value="hugo.damasio@nacionalgest.pt">hugo.damasio@nacionalgest.pt</option>
                                        <option value="hugo.pinheiro@nacionalgest.pt">hugo.pinheiro@nacionalgest.pt</option>
                                        <option value="isabel.marecos@nacionalgest.pt">isabel.marecos@nacionalgest.pt</option>
                                        <option value="isabel.pinheiro@nacionalgest.pt">isabel.pinheiro@nacionalgest.pt</option>
                                        <option value="ivany.sousa@nacionalgest.pt">ivany.sousa@nacionalgest.pt</option>
                                        <option value="joana.baginha@nacionalgest.pt">joana.baginha@nacionalgest.pt</option>
                                        <option value="joana.basilio@nacionalgest.pt">joana.basilio@nacionalgest.pt</option>
                                        <option value="joana.silva@nacionalgest.pt">joana.silva@nacionalgest.pt</option>
                                        <option value="joana.viana@nacionalgest.pt">joana.viana@nacionalgest.pt</option>
                                        <option value="joao.almeida@nacionalgest.pt">joao.almeida@nacionalgest.pt</option>
                                        <option value="joao.araujo@nacionalgest.pt">joao.araujo@nacionalgest.pt</option>
                                        <option value="joao.caldeira@nacionalgest.pt">joao.caldeira@nacionalgest.pt</option>
                                        <option value="joaquim.viegas@nacionalgest.pt">joaquim.viegas@nacionalgest.pt</option>
                                        <option value="jorge.silva@nacionalgest.pt">jorge.silva@nacionalgest.pt</option>
                                        <option value="jose.caldeira@nacionalgest.pt">jose.caldeira@nacionalgest.pt</option>
                                        <option value="leonor.fernandes@nacionalgest.pt">leonor.fernandes@nacionalgest.pt</option>
                                        <option value="ligia.domingos@nacionalgest.pt">ligia.domingos@nacionalgest.pt</option>
                                        <option value="lucia.bastos@nacionalgest.pt">lucia.bastos@nacionalgest.pt</option>
                                        <option value="lucia.coelho@nacionalgest.pt">lucia.coelho@nacionalgest.pt</option>
                                        <option value="luis.eloi@nacionalgest.pt">luis.eloi@nacionalgest.pt</option>
                                        <option value="luis.fernandes@nacionalgest.pt">luis.fernandes@nacionalgest.pt</option>
                                        <option value="luis.gomes@nacionalgest.pt">luis.gomes@nacionalgest.pt</option>
                                        <option value="luis.martins@nacionalgest.pt">luis.martins@nacionalgest.pt</option>
                                        <option value="luis.silva@nacionalgest.pt">luis.silva@nacionalgest.pt</option>
                                        <option value="luisa.marreiros@nacionalgest.pt">luisa.marreiros@nacionalgest.pt</option>
                                        <option value="luisa.silva@nacionalgest.pt">luisa.silva@nacionalgest.pt</option>
                                        <option value="mafalda.cadao@nacionalgest.pt">mafalda.cadao@nacionalgest.pt</option>
                                        <option value="marcia.hipolito@nacionalgest.pt">marcia.hipolito@nacionalgest.pt</option>
                                        <option value="maria.casimiro@nacionalgest.pt">maria.casimiro@nacionalgest.pt</option>
                                        <option value="maria.goreti@nacionalgest.pt">maria.goreti@nacionalgest.pt</option>
                                        <option value="mario.fragoso@nacionalgest.pt">mario.fragoso@nacionalgest.pt</option>
                                        <option value="mario.rodrigues@nacionalgest.pt">mario.rodrigues@nacionalgest.pt</option>
                                        <option value="micaelapereira80@gmail.com">micaelapereira80@gmail.com</option>
                                        <option value="miguel.duarte@nacionalgest.pt">miguel.duarte@nacionalgest.pt</option>
                                        <option value="mjose.antonio@nacionalgest.pt">mjose.antonio@nacionalgest.pt</option>
                                        <option value="mjose.jesus@nacionalgest.pt">mjose.jesus@nacionalgest.pt</option>
                                        <option value="nuno.martins@nacionalgest.pt">nuno.martins@nacionalgest.pt</option>
                                        <option value="nuno.teixeira@nacionalgest.pt">nuno.teixeira@nacionalgest.pt</option>
                                        <option value="octavio.barroco@nacionalgest.pt">octavio.barroco@nacionalgest.pt</option>
                                        <option value="orlanda.fidalgo@nacionalgest.pt">orlanda.fidalgo@nacionalgest.pt</option>
                                        <option value="patricia.ferreira@nacionalgest.pt">patricia.ferreira@nacionalgest.pt</option>
                                        <option value="patricia.soares@nacionalgest.pt">patricia.soares@nacionalgest.pt</option>
                                        <option value="paula.pereira@nacionalgest.pt">paula.pereira@nacionalgest.pt</option>
                                        <option value="paulo.barros@nacionalgest.pt">paulo.barros@nacionalgest.pt</option>
                                        <option value="paulo.fernandes@nacionalgest.pt">paulo.fernandes@nacionalgest.pt</option>
                                        <option value="paulo.prazeres@nacionalgest.pt">paulo.prazeres@nacionalgest.pt</option>
                                        <option value="paulo.rocha@nacionalgest.pt">paulo.rocha@nacionalgest.pt</option>
                                        <option value="paulo.santiago@nacionalgest.pt">paulo.santiago@nacionalgest.pt</option>
                                        <option value="paulo.silva@nacionalgest.pt">paulo.silva@nacionalgest.pt</option>
                                        <option value="pedro.carrola@nacionalgest.pt">pedro.carrola@nacionalgest.pt</option>
                                        <option value="pedro.dias@nacionalgest.pt">pedro.dias@nacionalgest.pt</option>
                                        <option value="pedro.espiguinha@nacionalgest.pt">pedro.espiguinha@nacionalgest.pt</option>
                                        <option value="pedro.guerreiro@nacionalgest.pt">pedro.guerreiro@nacionalgest.pt</option>
                                        <option value="pedro.luzia@nacionalgest.pt">pedro.luzia@nacionalgest.pt</option>
                                        <option value="pedro.martins@nacionalgest.pt">pedro.martins@nacionalgest.pt</option>
                                        <option value="pedro.nascimento@appacademia.pt">pedro.nascimento@appacademia.pt</option>
                                        <option value="pedro.pereira@nacionalgest.pt">pedro.pereira@nacionalgest.pt</option>
                                        <option value="reinaldo.monteiro@nacionalgest.pt">reinaldo.monteiro@nacionalgest.pt</option>
                                        <option value="rossana.coelho@nacionalgest.pt">rossana.coelho@nacionalgest.pt</option>
                                        <option value="rui.figueiredo@nacionalgest.pt">rui.figueiredo@nacionalgest.pt</option>
                                        <option value="rui.freitas@nacionalgest.pt">rui.freitas@nacionalgest.pt</option>
                                        <option value="rui.goncalves@nacionalgest.pt">rui.goncalves@nacionalgest.pt</option>
                                        <option value="rui.mateus@nacionalgest.pt">rui.mateus@nacionalgest.pt</option>
                                        <option value="rui.pinto@nacionalgest.pt">rui.pinto@nacionalgest.pt</option>
                                        <option value="rute.risio@nacionalgest.pt">rute.risio@nacionalgest.pt</option>
                                        <option value="sandra.dourado@nacionalgest.pt">sandra.dourado@nacionalgest.pt</option>
                                        <option value="sandra.montes@nacionalgest.pt">sandra.montes@nacionalgest.pt</option>
                                        <option value="sandra.soares@nacionalgest.pt">sandra.soares@nacionalgest.pt</option>
                                        <option value="sara.gomes@nacionalgest.pt">sara.gomes@nacionalgest.pt</option>
                                        <option value="sara.rocha@nacionalgest.pt">sara.rocha@nacionalgest.pt</option>
                                        <option value="sergio.jesus@nacionalgest.pt">sergio.jesus@nacionalgest.pt</option>
                                        <option value="silvia.alves@nacionalgest.pt">silvia.alves@nacionalgest.pt</option>
                                        <option value="silvia.costa@nacionalgest.pt">silvia.costa@nacionalgest.pt</option>
                                        <option value="simao.lagarto@nacionalgest.pt">simao.lagarto@nacionalgest.pt</option>
                                        <option value="sofia.guerreiro@nacionalgest.pt">sofia.guerreiro@nacionalgest.pt</option>
                                        <option value="sonia.faim@nacionalgest.pt">sonia.faim@nacionalgest.pt</option>
                                        <option value="susana.silva@nacionalgest.pt">susana.silva@nacionalgest.pt</option>
                                        <option value="susana.tome@nacionalgest.pt">susana.tome@nacionalgest.pt</option>
                                        <option value="tiago.nunes@nacionalgest.pt">tiago.nunes@nacionalgest.pt</option>
                                        <option value="valeria.ramalho@nacionalgest.pt">valeria.ramalho@nacionalgest.pt</option>
                                        <option value="vania.barbara@nacionalgest.pt">vania.barbara@nacionalgest.pt</option>
                                        <option value="vania.marques@nacionalgest.pt">vania.marques@nacionalgest.pt</option>
                                        <option value="vania.nobre@nacionalgest.pt">vania.nobre@nacionalgest.pt</option>
                                        <option value="vera.custodio@nacionalgest.pt">vera.custodio@nacionalgest.pt</option>
                                        <option value="vera.fialho@nacionalgest.pt">vera.fialho@nacionalgest.pt</option>
                                  </select>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="telemovel" name="telemovel" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="Telemóvel" />
                                  <label htmlFor="telemovel">Telemóvel</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-label-group">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Sim"
                                required
                              />{' '}
                              Vou à Convenção
                            </label>
                            </div>
                            <div className="form-check mb-4">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Nao"
                                required
                              />{' '}
                              Não vou à Convenção
                            </label>
                            </div>
                          </div>
                        </div>
                        {allowedEmails.includes(values.email) && values.comparencia === "Sim" && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-1">Precisa de alojamento na noite de 4 de Abril?</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="estadia_noite" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="estadia_noite"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Sim
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="estadia_noite" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="estadia_noite"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Fica para jantar no dia 5 de Abril?</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="jantar" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="jantar"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Janto
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="jantar" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="jantar"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não Janto
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.jantar == 'Sim' && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Restrições Alimentares</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="restricoes"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Sim
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="restricoes"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.jantar == 'Sim' && values.restricoes === "Sim" && (
                          <div className="row mb-4">
                            <div className="col-md-12">
                            <Field id="tipo_restricoes" name="tipo_restricoes" className="form-control" component="textarea" placeholder="Restrições">
                            </Field>
                          </div>
                          </div>
                        )}

                        {values.comparencia === "Sim" && values.jantar == 'Sim' && !notAllowToStay.includes(values.email) && (

                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Fica a dormir no hotel na noite de 5 de Abril?</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="hotel"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Durmo no hotel
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="hotel"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não durmo no hotel
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <a style={{width:'100%'}} className="btn btn-secondary rounded-0 btn-send mb-3" data-bs-toggle="modal" data-bs-target="#modal-03">
                              Ver Programa
                            </a>
                          </div>
                        </div>
                        <div class="modal fade" id="modal-03" tabindex="-1">
		                       <div class="modal-dialog modal-dialog-centered modal-lg">
            								<div class="modal-content">
            									<div class="modal-body p-6">
            										<div class="row">
            											<div class="col-md-12">
            												<h4 class="mb-2">Período da manhã</h4>
                                      <ul>
                                        <li><strong>09h45</strong> – Boas-vindas</li>
                                        <li><strong>10h05</strong> – Inovação ao serviço da informação, por João Araújo</li>
                                        <li><strong>10h35</strong> – Orador convidado</li>
                                        <li><strong>11h30</strong> – Pausa</li>
                                        <li><strong>12h00</strong> – Mesa Redonda - O futuro da indústria seguradora, com Dra. Joana Pina Pereira, Chief Distribution Officer da Generali Tranquilidade; Eng. Nuno Diniz Clemente, Diretor Geral Comercial da Fidelidade e Dr. Gustavo Barreto, Chief Commercial Officer, da Ageas.</li>
                                        <li><strong>13h00</strong> – Almoço</li>
                                    </ul>
              												<h4 class="mb-2">Período da tarde</h4>
                                    <ul>
                                        <li><strong>14h30</strong> – Abertura</li>
                                        <li><strong>14h45</strong> – 2024 em números, por Tiago Nunes e Rui Pinto</li>
                                        <li><strong>15h50</strong> – Orador convidado</li>
                                        <li><strong>16h10</strong> – Pausa</li>
                                        <li><strong>16h45</strong> – Proteger quem importa, por Luís Fernandes</li>
                                        <li><strong>17h05</strong> – Não ceder à procrastinação, por Ana Kohls</li>
                                        <li><strong>17h40</strong> – Para onde vamos?, por Cláudio Gonçalves</li>
                                    </ul>
                                    <p class="mb-0">Nota: O programa poderá sofrer ajustes, caso necessário.</p>
            											</div>
            										</div>
            									</div>
            								</div>
            							</div>
            						</div>
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine}>
                              Submeter Resposta
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  />)}
              </div>
        					</div>
                  {!this.state.formSubmitted && (
                    <div className="col-lg-6">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundImage: `url(${imagemFundo})`, backgroundPosition: 'center', backgroundSize:'100% 100%'}}>
                        <img src={imagemConvencao2024} alt="Convencao 2024" style={{ maxWidth: '70%', maxHeight: '70%' }} />
                      </div>
                    </div>

                )}
        				</div>
        			</div>
        		</div>
          </section>
            <RequestContact />
          </div>
          {/* /.content-wrapper */}
          <Footer />
            {this.state.loading && (
              <Spinner />
            )}
          <div className="progress-wrap">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
          </div>
  )
}
}

export default withRouter(InscricaoConvencao);
