import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';
import { Form, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom";
import imagemConvencao from '../../assets/img/convencao_23.png';
import imagemConvencao2024 from '../../assets/img/convencao_logo_25.svg';
import imagemFundo from '../../assets/img/fundo_convencao_25.png';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const required = value => (value ? undefined : 'Campo Obrigatório')

function Spinner() {
  return (
      <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
      <div></div><div></div><div></div><div></div><div></div>
      </div></div>
  )
}

class InscricaoConvencao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formSubmitted: false,
      error: null,
    }
  }


  onSubmit = async values => {
    await sleep(300)
    // const dev_token = '2276900aa8e0cfc2bde714738e61312b8ebe0a3b';
    const dev_token = '2276900aa8e0cfc2bde714738e61312b8ebe0a3b';
    this.setState({loading: true});
    const comp = values.comparencia === "Sim" ? true : false;
    const restri = values.restricoes === "Sim" ? true : false;
    const hotel_choice = values.hotel === "Sim" ? true : false;
    const estadia_noite_anterior = values.estadia_noite === "Sim" ? true : false;
    const fica_para_jantar = values.jantar === "Sim" ? true : false;
    await axios.post(`https://interno.nacionalgest.pt/api/eventos/convencao/inscricao/`, {
    // await axios.post(`http://127.0.0.1:8000/api/eventos/convencao/inscricao/`, {
      nome: values.nome,
      telemovel: values.telemovel,
      email: values.email,
      is_convidado: true,
      comparencia: comp,
      restricoes: restri,
      hotel: hotel_choice,
      hotel_noite_anterior: estadia_noite_anterior,
      jantar: fica_para_jantar,
      tipo_restricoes: values.tipo_restricoes,
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
  }}).then((response) => {
    console.log(response.data);
    if (response.status === 200) {
      if (response.data.error) {
        this.setState({
          error:response.data.error,
          loading: false
        })
      } else {
      this.setState({
        loading:false,
        formSubmitted: true
      })
    }
  }
  }).catch((error) => {
    this.setState({
      loading: false,
    });
    alert(error);
  });
  }

  handleSubmit = values  => {
      return this.onSubmit(values)
  }

  render() {

    const allowedEmails = [
      "luis.ribeiro@creditcare.pt","joao.correia@creditcare.pt","silvialanceiroluis@gmail.com","joaomorgado@atenaep.com","elisabete.figueira@hotaipeople.com",
      "fernanda.correia@hotaipeople.com","beatriz.costa@creditcare.pt",
  ]

  const notAllowToStay = [
    "leandro.fernandes@lluni.com","francisco.botelho@eco.pt","fmarques@gnt-sroc.com","valter.lopes@lemefinance.pt","joao.antas.martins@lemefinance.pt","alexandre.nogueira@anrseguros.com",
    "pedro.pintassilgo@anrseguros.com","rafaela.anrseguros@outlook.com", "lara.pedro@anrseguros.com", "joaquim-caetano@hotmail.com", "carinaantunes_6@hotmail.com"
  ]
  const notAllowedToDinner = [
    "leandro.fernandes@lluni.com","francisco.botelho@eco.pt","fmarques@gnt-sroc.com","valter.lopes@lemefinance.pt","joao.antas.martins@lemefinance.pt"
  ]



    return (
      <div>
      <div className="content-wrapper">
            <Header />
            {/* /header */}
            <section className="wrapper bg-light">
        			<div className="container pt-10 pb-8 pt-md-14 pb-md-12 text-center">
        				<div className="row">
        					<div className="col-lg-8 mx-auto">
        						<h1 className="display-1 mb-3">Inscrição Convenção Anual <br />NacionalGest 2025</h1>
        					</div>
        				</div>
        			</div>
        		</section>
        		<section className="wrapper bg-light">
            <div className="container">
        			<div className="card shadow-lg mb-15 mb-md-14">
        				<div className="row gx-0">
        					<div className="col-lg-6">
        						<div className="p-10 p-md-11 p-lg-11">
                    {this.state.error === 1 && (
                      <>
                      <h1>Já respondeu a este formulário!</h1>
                      <p>Uma resposta com este e-mail já foi submetida neste formulário. </p>
                      <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                      </>
                    )}
                    {this.state.formSubmitted && (
                      <>
                      <h1>Obrigado por responder!</h1>
                      <p>Mais perto da data voltaremos a entrar em contacto com mais pormenores. </p>
                      </>

                    )}
                    {!this.state.formSubmitted && this.state.error === null && (
                    <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{
                     }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>É com grande entusiasmo que o/a convidamos para a <b>Convenção Anual da NacionalGest</b>, que se realizará no dia <b>5 de abril de 2025</b>, no <b>Alambique Hotel Resort & Spa, no Fundão.</b></p>
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>Sob o tema <b>"Informar para Proteger"</b>, este encontro será um momento marcante de partilha, reflexão e crescimento. Teremos um dia repleto de <b>palestras inspiradoras, debates estratégicos e insights valiosos,</b> fundamentais para o nosso caminho conjunto.</p>
                            <p style={{fontSize:'19px', textAlign: 'justify'}}>A sua presença faz a diferença! Junte-se a nós nesta celebração do nosso propósito e do caminho que construímos juntos.</p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <Field id="nome" name="nome" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="nome" />
                                  <label htmlFor="nome">Nome</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                              <Field id="email" name="email" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select" required>
                                        <option disabled selected value="">Seleccione o seu e-mail</option>
                                        <option value="alda@cegrel.pt">alda@cegrel.pt</option>
                                        <option value="alexandre.nogueira@anrseguros.com">alexandre.nogueira@anrseguros.com</option>
                                        <option value="artur@cegrel.pt">artur@cegrel.pt</option>
                                        <option value="beatriz.costa@creditcare.pt">beatriz.costa@creditcare.pt</option>
                                        <option value="carinaantunes_6@hotmail.com">carinaantunes_6@hotmail.com</option>
                                        <option value="elisabete.figueira@hotaipeople.com">elisabete.figueira@hotaipeople.com</option>
                                        <option value="fernanda.correia@hotaipeople.com">fernanda.correia@hotaipeople.com</option>
                                        <option value="fmarques@gnt-sroc.com">fmarques@gnt-sroc.com</option>
                                        <option value="francisco.botelho@eco.pt">francisco.botelho@eco.pt</option>
                                        <option value="joao.antas.martins@lemefinance.pt">joao.antas.martins@lemefinance.pt</option>
                                        <option value="joao.correia@creditcare.pt">joao.correia@creditcare.pt</option>
                                        <option value="joaomorgado@atenaep.com">joaomorgado@atenaep.com</option>
                                        <option value="joaquim-caetano@hotmail.com">joaquim-caetano@hotmail.com</option>
                                        <option value="lara.pedro@anrseguros.com">lara.pedro@anrseguros.com</option>
                                        <option value="leandro.fernandes@lluni.com">leandro.fernandes@lluni.com</option>
                                        <option value="lguanilho@cegrel.pt">lguanilho@cegrel.pt</option>
                                        <option value="luis.ribeiro@creditcare.pt">luis.ribeiro@creditcare.pt</option>
                                        <option value="mfino@cegrel.pt">mfino@cegrel.pt</option>
                                        <option value="margarida@cegrel.pt">margarida@cegrel.pt</option>
                                        <option value="paula@cegrel.pt">paula@cegrel.pt</option>
                                        <option value="pedro.pintassilgo@anrseguros.com">pedro.pintassilgo@anrseguros.com</option>
                                        <option value="rafaela.anrseguros@outlook.com">rafaela.anrseguros@outlook.com</option>
                                        <option value="silvialanceiroluis@gmail.com">silvialanceiroluis@gmail.com</option>
                                        <option value="valter.lopes@lemefinance.pt"> valter.lopes@lemefinance.pt</option>




                                    </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="telemovel" name="telemovel" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="Telemóvel" />
                                  <label htmlFor="telemovel">Telemóvel</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-label-group">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Sim"
                                required
                              />{' '}
                              Vou à Convenção
                            </label>
                            </div>
                            <div className="form-check mb-4">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Nao"
                                required
                              />{' '}
                              Não vou à Convenção
                            </label>
                            </div>
                          </div>
                        </div>
                        {allowedEmails.includes(values.email) && values.comparencia === "Sim" && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-1">Precisa de alojamento na noite de 4 de Abril?</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="estadia_noite" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="estadia_noite"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Sim
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="estadia_noite" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="estadia_noite"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.email && !notAllowedToDinner.includes(values.email) && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Fica para jantar no dia 5 de Abril?</h6>
                                <p style={{fontSize:'19px', textAlign: 'justify'}}>À noite, prepare-se para um ambiente descontraído e envolvente, repleto de <b>momentos de convívio, surpresas e uma viagem sensorial pelos sabores e tradições culturais.</b></p>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="jantar" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="jantar"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Janto
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="jantar" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="jantar"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não Janto
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.jantar == 'Sim' && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Restrições Alimentares</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="restricoes"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Sim
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="restricoes"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.jantar == 'Sim' && values.restricoes === "Sim" && (
                          <div className="row mb-4">
                            <div className="col-md-12">
                            <Field id="tipo_restricoes" name="tipo_restricoes" className="form-control" component="textarea" placeholder="Restrições">
                            </Field>
                          </div>
                          </div>
                        )}

                        {values.comparencia === "Sim" && values.jantar == 'Sim' && !notAllowToStay.includes(values.email) && (

                          <div className="row">
                            <div className="form-label-group">
                            <h6 className="mb-3">Fica a dormir no hotel na noite de 5 de Abril?</h6>
                              <p style={{fontSize:'19px', textAlign: 'justify'}}>No dia <b>6 de abril</b>, até à hora de check-out, poderá usufruir dos serviços comuns do <b>Golden Rock Water Spa</b> (piscinas interiores aquecidas, jacuzzis, saunas, banho turco, hammam e ginásio). Para ter acesso, é obrigatória a utilização de touca e chinelos.</p>
                                <div className="form-check">
                                <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="hotel"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Durmo no hotel
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="hotel"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não durmo no hotel
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <a style={{width:'100%'}} className="btn btn-secondary rounded-0 btn-send mb-3" data-bs-toggle="modal" data-bs-target="#modal-03">
                              Ver Programa
                            </a>
                          </div>
                        </div>
                        <div class="modal fade" id="modal-03" tabindex="-1">
		                       <div class="modal-dialog modal-dialog-centered modal-lg">
            								<div class="modal-content">
            									<div class="modal-body p-6">
            										<div class="row">
            											<div class="col-md-12">
            												<h4 class="mb-2">Período da manhã</h4>
                                      <ul>
                                        <li><strong>09h45</strong> – Boas-vindas</li>
                                        <li><strong>10h05</strong> – Inovação ao serviço da informação, por João Araújo</li>
                                        <li><strong>10h35</strong> – Orador convidado</li>
                                        <li><strong>11h30</strong> – Pausa</li>
                                        <li><strong>12h00</strong> – Mesa Redonda - O futuro da indústria seguradora, com Dra. Joana Pina Pereira, Chief Distribution Officer da Generali Tranquilidade; Eng. Nuno Diniz Clemente, Diretor Geral Comercial da Fidelidade e Dr. Gustavo Barreto, Chief Commercial Officer, da Ageas.</li>
                                        <li><strong>13h00</strong> – Almoço</li>
                                    </ul>
              												<h4 class="mb-2">Período da tarde</h4>
                                    <ul>
                                        <li><strong>14h30</strong> – Abertura</li>
                                        <li><strong>14h45</strong> – 2024 em números, por Tiago Nunes e Rui Pinto</li>
                                        <li><strong>15h50</strong> – Orador convidado</li>
                                        <li><strong>16h10</strong> – Pausa</li>
                                        <li><strong>16h45</strong> – Proteger quem importa, por Luís Fernandes</li>
                                        <li><strong>17h05</strong> – Não ceder à procrastinação, por Ana Kohls</li>
                                        <li><strong>17h40</strong> – Para onde vamos?, por Cláudio Gonçalves</li>
                                    </ul>
                                    <p class="mb-0">Nota: O programa poderá sofrer ajustes, caso necessário.</p>
            											</div>
            										</div>
            									</div>
            								</div>
            							</div>
            						</div>
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine}>
                              Submeter Resposta
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  />)}
              </div>
        					</div>
                  {!this.state.formSubmitted && (
                    <div className="col-lg-6">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundImage: `url(${imagemFundo})`, backgroundPosition: 'center', backgroundSize:'100% 100%'}}>
                        <img src={imagemConvencao2024} alt="Convencao 2024" style={{ maxWidth: '70%', maxHeight: '70%' }} />
                      </div>
                    </div>

                )}
        				</div>
        			</div>
        		</div>
          </section>
            <RequestContact />
          </div>
          {/* /.content-wrapper */}
          <Footer />
            {this.state.loading && (
              <Spinner />
            )}
          <div className="progress-wrap">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
          </div>
  )
}
}

export default withRouter(InscricaoConvencao);
